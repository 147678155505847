angular.module('fingerink')
    .service('changeStampModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function () {
            this.modalInstance = $uibModal.open({
                backdrop: 'static',
                templateUrl: 'modals/changeStampModal.tpl.html',
                controller: 'changeStampModalCtrl',
                controllerAs: 'controller',
                animation: false,
                size: 'lg'
            });

            return this.modalInstance;
        };

    })
    .controller('changeStampModalCtrl', function cambiarSelloModalCtrl($scope, session, companyService, $timeout, $uibModalInstance, $translate) {
        var that = this;

        var user = session.get().user;
        that.type = 'round';
        that.text1 = user.name + ' ' + user.surname;
        that.text2 = user.company.nifCif;
        that.text3 = user.company.address;
        that.text4 = user.company.name;


        that.guardar = function () {
            $uibModalInstance.close(that.dataURL);
        };
    })

    .directive('rectangleSelloCanvas', function () {
        return {
            scope: {
                parent: '='
            },
            link: function (scope, element, attrs) {
                var canvas = element;
                var ctx = canvas[0].getContext("2d");

                scope.$watch('parent', function () {
                    redibujar();
                    scope.parent.dataURL = canvas[0].toDataURL();

                }, true);


                function redibujar() {

                    ctx.clearRect(0, 0, 400, 200);
                    ctx.lineWidth = 2;
                    roundRect(ctx, 0, 0, 400, 200, {
                        tl: 50,
                        tr: 50,
                        br: 50,
                        bl: 50
                    }, false);
                    ctx.lineWidth = 1;
                    roundRect(ctx, 10, 10, 380, 180, {
                        tl: 50,
                        tr: 50,
                        br: 50,
                        bl: 50
                    }, false);

                    ctx.font = "bold 18px Courier";
                    ctx.textAlign = "center";

                    if (scope.parent.text1) {
                        ctx.fillText(scope.parent.text1, 200, 50);
                    }
                    ctx.font = " 15px Courier";
                    if (scope.parent.text2) {
                        ctx.fillText(scope.parent.text2, 200, 90);
                    }
                    if (scope.parent.text3) {
                        ctx.fillText(scope.parent.text3, 200, 120);
                    }
                    if (scope.parent.text4) {
                        ctx.fillText(scope.parent.text4, 200, 150);
                    }
                }


                function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
                    if (typeof stroke == 'undefined') {
                        stroke = true;
                    }
                    if (typeof radius === 'undefined') {
                        radius = 5;
                    }
                    if (typeof radius === 'number') {
                        radius = {
                            tl: radius,
                            tr: radius,
                            br: radius,
                            bl: radius
                        };
                    } else {
                        var defaultRadius = {
                            tl: 0,
                            tr: 0,
                            br: 0,
                            bl: 0
                        };
                        for (var side in defaultRadius) {
                            radius[side] = radius[side] || defaultRadius[side];
                        }
                    }
                    ctx.beginPath();
                    ctx.moveTo(x + radius.tl, y);
                    ctx.lineTo(x + width - radius.tr, y);
                    ctx.quadraticCurveTo(x + width - radius.tr, radius.tr, x + width, y + radius.tr);
                    ctx.lineTo(x + width, y + height - radius.br);
                    ctx.quadraticCurveTo(x + width - radius.tr, y + height - radius.tr, x + width - radius.br, y + height);
                    ctx.lineTo(x + radius.bl, y + height);
                    ctx.quadraticCurveTo(x + radius.tr, y + height - radius.tr, x, y + height - radius.bl);
                    ctx.lineTo(x, y + radius.tl);
                    ctx.quadraticCurveTo(x + radius.tr, y + radius.tr, x + radius.tl, y);
                    ctx.closePath();
                    if (fill) {
                        ctx.fill();
                    }
                    if (stroke) {
                        ctx.stroke();
                    }

                }




            }
        };
    })


    .directive('roundSelloCanvas', function () {
        return {
            scope: {
                parent: '='
            },
            link: function (scope, element, attrs) {
                var canvas = element;
                var ctx = canvas[0].getContext("2d");


                var space = Math.PI / 12;

                function redibujar() {
                    ctx.clearRect(0, 0, 400, 400);
                    ctx.font = "bold 40px Courier";

                    ctx.beginPath();
                    ctx.arc(200, 200, 200, 0, Math.PI * 2, false);
                    ctx.stroke();
                    drawDottedCircle(180, 200, 200, 195);

                    if (scope.parent.text1) {
                        textCircle(scope.parent.text1, 200, 200, 145, space, 1);
                    }
                    if (scope.parent.text2) {
                        textCircle(scope.parent.text2, 200, 200, 145, space);
                    }
                    drawDottedCircle(160, 200, 200, 148);

                    ctx.font = "20px Courier";
                    if (scope.parent.text3) {
                        textCircle(scope.parent.text3, 200, 200, 120, space);
                    }


                    ctx.font = "bold 20px Courier";
                    if (scope.parent.text4) {
                        ctx.textAlign = "center";
                        ctx.fillText(scope.parent.text4, 200, 190);
                    }
                }



                function textCircle(text, x, y, radius, space, top) {
                    space = space || 0;
                    var numRadsPerLetter = (Math.PI - space * 2) / text.length;
                    ctx.save();
                    ctx.translate(x, y);
                    var k = (top) ? 1 : -1;
                    ctx.rotate(-k * ((Math.PI - numRadsPerLetter) / 2 - space));
                    for (var i = 0; i < text.length; i++) {
                        ctx.save();
                        ctx.rotate(k * i * (numRadsPerLetter));
                        ctx.textAlign = "left";
                        ctx.textBaseline = (!top) ? "top" : "bottom";
                        ctx.fillText(text[i], 0, -k * (radius));
                        ctx.restore();
                    }
                    ctx.restore();
                }

                function drawDottedCircle(dotsPerCircle, centerX, centerY, radius) {
                    var interval = (Math.PI * 2) / dotsPerCircle;
                    for (var i = 0; i < dotsPerCircle; i++) {

                        let desiredRadianAngleOnCircle = interval * i;
                        var x = centerX + radius * Math.cos(desiredRadianAngleOnCircle);
                        var y = centerY + radius * Math.sin(desiredRadianAngleOnCircle);
                        ctx.beginPath();
                        ctx.arc(x, y, 1, 0, Math.PI * 2);
                        ctx.closePath();
                        ctx.fill();
                    }
                }

                scope.$watch('parent', function () {
                    redibujar();
                    scope.parent.dataURL = canvas[0].toDataURL();
                }, true);


            }
        };
    });
